
import { defineComponent } from 'vue'
import axios from 'axios'
import { HOST } from '../../constants'
import { router } from '../main'

const MAX_INPUT_LENGTH = 14
export default defineComponent({
  name: 'Login',
  data() {
    return {
      maxInputLength: MAX_INPUT_LENGTH,
      password: '',
      placeholders: Array(MAX_INPUT_LENGTH).fill('_'),
      loading: false
    }
  },
  methods: {
    updateDisplay() {
      if (this.password.length > this.placeholders.length) {
        this.password = this.password.slice(0, this.placeholders.length) // Prevent overflow
      }
    },
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${HOST}/login`,
          {
            password
          },
          { withCredentials: true }
        )
        .then(function(res) {
        })
        .catch(function() {
        })
      router.go(0)

    }
  }
})
