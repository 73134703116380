<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { HOST } from '../../constants'
import { router } from '../main'

const MAX_INPUT_LENGTH = 14
export default defineComponent({
  name: 'Login',
  data() {
    return {
      maxInputLength: MAX_INPUT_LENGTH,
      password: '',
      placeholders: Array(MAX_INPUT_LENGTH).fill('_'),
      loading: false
    }
  },
  methods: {
    updateDisplay() {
      if (this.password.length > this.placeholders.length) {
        this.password = this.password.slice(0, this.placeholders.length) // Prevent overflow
      }
    },
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${HOST}/login`,
          {
            password
          },
          { withCredentials: true }
        )
        .then(function(res) {
        })
        .catch(function() {
        })
      router.go(0)

    }
  }
})
</script>

<template>
  <div class="loginWrapper">
    <div class="loginContainer">
      <form @submit.prevent="handleSubmit">
        <label class="passwordLabel" htmlFor="password">Password</label>
        <div class="passwordContainerWrapper">
          <div class="passwordContainer">
            <!-- Hidden input field -->
            <input
              v-model="password"
              type="password"
              name="password"
              class="hiddenPasswordInput"
              autocomplete="off"
              :maxLength="maxInputLength"
              @input="updateDisplay"
            />
            <!-- Visual password display -->
            <div class="passwordDisplay">
            <span
              v-for="(char, index) in placeholders"
              :key="index"
              class="passwordCharWrapper"
            >
              <!-- Show * for entered characters -->
              <span v-if="index < password.length" class="passwordChar"><svg width="15" height="15" viewBox="0 0 16 16"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
<path
  d="M9.00001 0H7.00001L5.51292 4.57681L0.700554 4.57682L0.0825195 6.47893L3.97581 9.30756L2.48873 13.8843L4.10677 15.0599L8.00002 12.2313L11.8933 15.0599L13.5113 13.8843L12.0242 9.30754L15.9175 6.47892L15.2994 4.57681L10.4871 4.57681L9.00001 0Z"
  fill="#fa0791" />
</svg></span>
            </span>
            </div>
          </div>
          <button class="sendPasswordButton" :disabled="loading">></button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">

.loginWrapper {
  background-color: #000;
  color: #fff;
  height: 100%;
  width: 100%;

  .loginContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    form {
      font-family: 'Orbitron', sans-serif;
      width: 100%;

      .passwordLabel {
        display: flex;
        width: 100%;
        text-align: center;
        animation: color-change 5s infinite;
        text-transform: uppercase;
        font-size: 3rem;
        justify-content: center;
      }

      .passwordContainerWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        .passwordContainer {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 4rem;
          border: 5px solid #fa0791;

          .hiddenPasswordInput {
            position: absolute;
            height: 4rem;
            width: 100%;
            font-size: 3rem;
            opacity: 0; /* Hide input while keeping it functional */
            z-index: 2;
            background: transparent;
            color: transparent;
            border: none;
          }

          .passwordDisplay {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 4rem;
            font-size: 3rem;
            font-family: monospace;
            color: white;
            background-color: #000;
            z-index: 1;
          }

          .passwordCharWrapper {
            display: flex;
            flex: 1;
          }

          .passwordChar {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            text-align: center;
            padding: 0 5px;
            color: #fa0791;
          }
        }

        .sendPasswordButton {
          display: flex;
          margin-left: 10px;
          justify-content: center;
          width: 125px;
          padding: 0;
          color: #fa0791;
          border: 5px solid #fa0791;
          background-color: #000;
          text-align: center;
          align-items: center;
          font-size: 3rem;
          font-weight: bold;
          cursor: pointer;
        }

        .sendPasswordButton:hover {
          opacity: 0.8;
        }
      }

      @media (max-width: 750px) {
        .passwordLabel {
          font-size: 1.5rem;
        }
        .passwordContainerWrapper {
          .passwordContainer {
            height: 2rem;
            border-width: 2px;

            .hiddenPasswordInput {
              height: 2rem;
              font-size: 1.5rem;
            }

            .passwordDisplay {
              height: 2rem;
              font-size: 1.5rem;
            }

            .passwordChar {
              padding: 0 2px;
            }
          }

          .sendPasswordButton {
            width: 65px;
            border-width: 2px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 1350px) {
    .loginContainer {
      width: 75%;
    }
  }

  @media (max-width: 900px) {
    .loginContainer {
      width: 90%;
    }
  }

}

@keyframes color-change {
  0% {
    color: #ff36e1;
  }
  50% {
    color: #fa0791;
  }
  100% {
    color: #de31a1;
  }
}
</style>
